import "../../scss/shortcodes/casinoTable.scss";

//document.querySelectorAll('.sort-type').forEach(el => el.addEventListener('click', event => {
document.addEventListener("click", function(e){
    const el = e.target.closest('.sort-type');
    if(!el) return true;
    const parentContainer = el.closest('.sort-elements-container');
    const sortBy = el.getAttribute('data-value');
    var sortType = 'asc';
    if(el.classList.contains('sort-ascending')){
        sortType = 'desc';
    }
    [].forEach.call(parentContainer.querySelectorAll(".sort-type"), function(el) {
        el.classList.remove("sort-descending");
        el.classList.remove("sort-ascending");
    });
    if(sortType == 'desc'){
        el.classList.add('sort-descending');
    }else{
        el.classList.add('sort-ascending');
    }
    if (parentContainer.querySelector(".show-more-button")){
        parentContainer.querySelectorAll(".sorted-element").forEach((element) => {
            element.classList.add('hidden');
            element.classList.remove('showed');
        });
    }
    var elementList = parentContainer.querySelectorAll(".sorted-element");
    var elementListArray = Array.from(elementList);
    elementListArray.sort(function(a, b){
        var aVal = a.getAttribute('data-'+sortBy).replace(/\D/g, '');
        var bVal = b.getAttribute('data-'+sortBy).replace(/\D/g, '');
        return (sortType == 'desc' ? aVal - bVal : bVal - aVal);
    });
    elementListArray.forEach(e => parentContainer.querySelector(".sorted-elements").appendChild(e));
    
    if (parentContainer.querySelector(".show-more-button")){
        parentContainer.querySelectorAll(".sorted-element").forEach((element, index) => {
            if(index < 10){
                element.classList.remove('hidden');
            }else{
                if (!element.closest('.sort-elements-container').querySelector(".show-more-button.show-more-casinos")){
                    element.classList.remove('hidden');
                    element.classList.add('showed');
                }
            }
        });
    }
    return false;
});
//}));
/*
let bonusCodeButtons = document.querySelectorAll(".bonus-code-button");
bonusCodeButtons.forEach((bonusCodeButton) => {
    bonusCodeButton.addEventListener("click", () => {
        bonusCodeButton.classList.add("revealed");
        let bonusCodeField = bonusCodeButton.querySelector('.bonus-code-text');
        let bonusCodeWrap = bonusCodeButton.querySelector('.bonus-code-wrap');
        bonusCodeWrap.classList.remove('ps-3','pe-2','pe-lg-1');
        bonusCodeField.textContent = bonusCodeField.dataset.code;
    });
});*/
document.addEventListener("click", function(e){
    const el = e.target.closest('.bonus-code-button');
    if(!el) return true;
    el.classList.add("revealed");
    let bonusCodeField = el.querySelector('.bonus-code-text');
    let bonusCodeWrap = el.querySelector('.bonus-code-wrap');
    bonusCodeWrap.classList.remove('ps-3','pe-2','pe-lg-1');
    bonusCodeField.textContent = bonusCodeField.dataset.code;
    return false;
});

/*
let showMoreButtons = document.querySelectorAll('.show-more-button');
showMoreButtons.forEach((showMoreButton) => {
    showMoreButton.addEventListener("click", () => {*/
document.addEventListener("click", function(e){
    const showMoreButton = e.target.closest('.show-more-button');
    if(!showMoreButton) return true;
    if (showMoreButton.classList.contains('show-more-casinos')){
        let casinoRows = showMoreButton.parentElement.querySelectorAll('.sorted-element.hidden');
        showMoreButton.classList.remove('show-more-casinos');
        showMoreButton.innerHTML = 'SHOW LESS CASINOS\n' +
            '<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M11.5 12L6.5 7L1.5 12M11.5 6L6.5 1L1.5 6" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '</svg>';
        casinoRows.forEach((element) => {
            element.classList.add('showed');
            element.classList.remove('hidden');
        });
    }else{
        let casinoRows = showMoreButton.parentElement.querySelectorAll('.sorted-element.showed');
        showMoreButton.classList.add('show-more-casinos');
        showMoreButton.innerHTML = '  SHOW MORE CASINOS\n' +
            '<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M1 1L6 6L11 1M1 7L6 12L11 7" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '</svg>';
        casinoRows.forEach((element) => {
            element.classList.add('hidden');
            element.classList.remove('showed');
        });
    }
});
    /*});
});*/



